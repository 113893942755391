.bristol-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bristol-menu-item img {
  width: 50px;
}
.bristol-menu-item span {
  display: flex;
  flex-wrap: wrap;
  white-space: break-spaces;
  margin-left: 24px;
}
