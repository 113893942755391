.add-new-movement {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 40px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.actions {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
}
