.toolbar {
  /* background-color: white; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, */
  /* rgba(17, 17, 26, 0.05) 0px 8px 32px; */
}
.add-new {
  background: #ffffff;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  position: absolute;
  transform: translateY(-40px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.add-new:active {
  width: 60px;
  height: 60px;
}
.add-new,
.add-new svg {
  transition: all 0.15s ease-in-out;
}
.add-new:active svg {
  opacity: 0.2;
}
.toolbar-icon {
  width: 50px;
}
