.movement-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 1px solid #f7f7f7;
  width: 100%;
}

.movement-date {
  font-weight: bold;
}
.movement-date .time {
  opacity: 0.5;
  font-style: italic;
  font-weight: 400;
}

.bristol-preview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
img.bristol-img {
  width: 80px;
}

span.bristol-description {
  color: gray;
  font-style: italic;
  opacity: 0.7;
}

.movement-attributes {
  display: flex;
  flex-wrap: wrap;
}
.movement-attr {
  display: flex;
  padding: 4px 11px;
  align-items: center;
  border: 1px solid #c3fbc3;
  border-radius: 30px;
  font-size: 14px;
  margin-right: 10px;
  background: #dffddf;
  color: #18bd05;
  margin-bottom: 10px;
}
span.attr-label {
  margin-left: 12px;
}

.movement-size {
  font-size: 12px;
  font-weight: bold;
  padding: 5px 12px;
  border: 1px solid;
  border-radius: 20px;
}
.movement-size.SMALL {
  background: rgb(238, 250, 255);
  color: rgb(2, 0, 119);
  border-color: rgb(159, 226, 255);
}
.movement-size.LARGE {
  background: rgb(0, 121, 46);
  color: white;
}
.movement-size.NORMAL {
  background: rgb(228, 255, 232);
  color: rgb(3, 214, 66);
}
