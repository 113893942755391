body {
  margin: 0;
  height: 100vh;
  font-family: sans-serif;
  user-select: none;
  touch-action: pan-y;
}
input {
  user-select: all;
}
