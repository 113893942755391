.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.form-inputs {
  display: flex;
  flex-direction: column;
}

button.login-button:disabled {
  background: #f4f7f9;
  color: #d1d1d1;
}
.form-inputs.invalid input {
  border-color: red;
}
.form-inputs input {
  outline: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 3px solid #4ec1f5;
  box-shadow: rgb(0 0 0 / 1%) 0px 1px 1px, rgb(0 0 0 / 1%) 0px 2px 2px,
    rgb(0 0 0 / 1%) 0px 4px 4px, rgb(0 0 0 / 1%) 0px 8px 8px,
    rgb(0 0 0 / 1%) 0px 16px 16px;
}
.login-button {
  display: flex;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-style: italic;
  border: 0;
  background: #dff3fd;
  color: #4ec1f5;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
