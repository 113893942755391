.movement-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 100vh;
  overflow-y: scroll;
  padding-top: 65px;
  box-sizing: border-box;
  padding-bottom: 50px;
}
.timeline {
  background-size: 128px;
}
.new-day-header {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}
.day-of-week {
  font-size: 24px;
  font-weight: bold;
}
.date {
  color: #4fc1f5;
  font-weight: 100;
}
