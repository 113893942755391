.logo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4ec1f5;
  font-size: 30px;
  font-style: italic;
  filter: drop-shadow(2px 4px 6px #4ec1f522);
}
img.logo-img {
  transform: translateY(7px);
  margin-right: 10px;
}

.logo-header.small {
  font-size: 14px;
  filter: none;
}
.logo-header.small img.logo-img {
  width: 20px;
  transform: translateY(4px);
  margin-right: 3px;
}
