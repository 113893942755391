.header {
  font-size: 14px;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 40px;
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  padding-left: 10px;
  justify-content: space-between;
  align-items: center;
  background: rgb(247 247 247 / 30%);
  box-shadow: 0px 4px 16px 0px rgb(223 236 253 / 70%);
  background-color: rgba(255, 255, 255, 0.99);
}
.time-ago {
  color: gray;
  padding: 10px;
}
